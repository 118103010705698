export default {
  pagination: {
    numberOfRows: 'Antal rader',
    pageInfo: 'Sida _ av _',
    first: 'Första',
    previous: 'Föregående',
    next: 'Nästa',
    last: 'Sista',
    filter: 'Filtrera',
  },
  environment: {
    prod: 'Produktionsmiljö',
    test: 'Testmiljö',
    dev: 'Utvecklingsmiljö',
    lab: 'LabEnvironment',
  },
  appname: 'Diremo',
  loggingIn: 'Loggar in',
  accessDenied: 'Åtkomst nekad',
  fetching: 'hämtar..',
  todolist: 'Att-göra lista',
  home: 'Hem',
  show: 'Visa',
  rows: 'Rader',
  search: 'Sök',
  objectnumber: 'Produktnummer',
  propertynumber: 'Fastighetsnummer',
  address: 'Adress',
  location: 'Belägenhetsadress',
  addresses: 'Adresser',
  publishDate: 'Publiceringsdatum',
  publishFromDate: 'Publiceringstid from',
  publishToDate: 'Publiceringstid tom',
  priority: 'Förtur',
  dismissedUntil: 'Uppsagd till',
  dismissed: 'Uppsagd',
  rentalrestriction: 'Uthyrningsspärr',
  rentalrestrictionFrom: 'Uthyrningsspärr from',
  rentalrestrictionTo: 'Uthyrningsspärr tom',
  curentRentalrestriction: 'Aktuell uthyrningsspärr',
  name: 'Namn',
  firstname: 'Förnamn',
  lastname: 'Efternamn',
  id: 'Id',
  productId: 'Produkt ID',
  customerId: 'Kundnummer',
  customertype: 'Kundtyp',
  contract: 'Avtal',
  contractNumber: 'Avtalsnummer',
  constractowner: 'Avtalsinnehavare',
  rentalContract: 'Avtal',
  product: 'Produkt',
  products: 'Produkter',
  projectmanager: 'Projektledare',
  terminated: 'Uppsagt',
  moveout: 'Avflyttning',
  movein: 'Inflyttning',
  moveinDate: 'Inflyttningsdatum',
  block: 'Spärr',
  rentalBlock: 'Uthyrningsspärr',
  terminationTime: 'Uppsägningstid',
  description: 'Beskrivning',
  descriptionText: 'Beskrivningstext',
  reserveFromDate: 'Bokn.from',
  reserveUntilDate: 'Bokn.tom',
  numberOfReservations: 'Ant.bokn',
  rentable: 'Uthyrbar',
  personalNumber: 'Personnummer',
  personalNumberError: 'Felaktigt personnummer',
  personalCode: 'Personal code',
  contractFrom: 'Avtalstid from',
  quedate: 'Ködatum',
  queuedateTime: 'Ködatum tid Timmar/minuter',
  queuetime: 'Kötid',
  quedateChangeDescription: 'Ködatum ändringsorsak',
  priorityChangeDescription: 'Förtur ändringsorsak',
  answer: 'Svar',
  latestAnswerDate: 'Svar senast',
  removedDate: 'Avregistrerad',
  removedCause: 'Orsak',
  date: 'Datum',
  caseType: 'Ärendetyp',
  comment: 'Kommentar',
  comments: 'Kommentarer',
  residentialLimitLabel: 'Bospärr',
  residentialLimit: 'Bospärrdatum',
  inspection: 'Besiktning',
  contractToDate: 'Avtal tom',
  customer: 'Kund',
  phone: 'Telefon',
  email: 'E-post',
  pet: 'Husdjur',
  worker: 'Bovärd',
  sqrMtrs: 'Yta',
  sqrMtrsTotal: 'Total yta',
  type: 'Typ',
  floor: 'Våning',
  rent: 'Hyra',
  webb: 'Webb',
  periodsOfNotice: 'Uppsägningstid',
  paymentPeriod: 'Betalningsperiod',
  storenumber: 'Förrådsnummer',
  storeaddress: 'Förrådsadress',
  text: 'Text',
  property: 'Fastighet',
  fromdate: 'From datum',
  todate: 'Tom datum',
  from: 'From',
  to: 'Tom',
  admissionTerm: 'Antagningstermin',
  admissionYear: 'Antagningsår',
  removalDate: 'Avregistreringsdatum',
  removalCause: 'Avregistreringsorsak',
  invoiceType: 'Aviseringssätt',
  paymentType: 'Betalningssätt',
  bank: 'Bank',
  clearing: 'Clearingnummer',
  bankaccount: 'Bankkontonummer',
  rentalcollectionallowed: 'Tillåt inkasso',
  invoiceblock: 'Avispärr',
  maintobject: 'Fastighetsobjekt',
  corridorcontactFrom: 'Korridorkontakt from',
  errorPageMessage: 'Ett fel uppstod. Var vänlig försök igen senare.',
  errorModalTitle: 'Ett fel har inträffat',
  noServicesAvailable: 'Du har inte tillgång till några tjänster i appen',
  notPermitted: 'Ej behörig',
  copyErrorToClipboard: 'Klicka för att kopiera detaljer om fel',
  emptyAccesList: 'Du har inga tillträden för närvarande',
  termination: 'Uppsägning',
  prio: 'Prioritet',
  measures: 'Åtgärder',
  measure: 'Åtgärd',
  coAddress: 'Co Adress',
  streetAddress: 'Gatuadress',
  zipCode: 'Postnummer',
  city: 'Ort',
  country: 'Land',
  emptyResult: 'Din sökning matchade inga objekt',
  emptyResultSignContract: 'Det finns inga avtal att signera',
  emptyResultConfirmTermination: 'Det finns inga uppsägningar att bekräfta',
  emptyResultContractProposal: 'Det finns inga avtalsförslag att skicka',
  number: 'Nummer',
  noOfApartments: 'Antal lägenheter',
  noOfCorridorRooms: 'Antal korridorrum',
  lotteryFrom: 'Lottning from',
  lotteryTo: 'Lottning tom',
  publishedFrom: 'Publicerad from',
  publishedTo: 'Publicerad tom',
  subletter: 'Andrahandshyresgäst',
  status: 'Status',
  finished: 'Klart',
  handler: 'Handläggare',
  createSubletting: 'Skapa ny andrahandsansökan',
  editSubletting: 'Ändra andrahandsansökan',
  exemptions: 'Dispenser',
  createExemption: 'Skapa ny dispensansökan',
  editExemption: 'Ändra dispensansökan',
  editCheck: 'Ändra kontroll',
  deleteExemption: 'Ta bort dispensansökan',
  deleteExemptionQuestion: 'Vill du verkligen ta bort dispensen?',
  createAddress: 'Skapa en ny adress',
  createCheck: 'Skapa ny kundkontroll',
  createRestriction: 'Skapa ny kundspärr',
  login: 'Logga in',
  loginError: 'Felaktig e-postadress eller lösenord',
  username: 'Användarnamn',
  password: 'Lösenord',
  terminationAgreement: 'Uppsägning av avtal',
  confirm: 'Bekräfta',
  execute: 'Utför åtgärder',
  cancel: 'Avbryt',
  redo: 'Ångra',
  help: 'Hjälp',
  ok: 'Ok',
  continue: 'Gå vidare',
  keepon: 'Fortsätt',
  createNew: 'Skapa ny',
  cause: 'Orsak',
  period: 'Period',
  getReport: 'Hämta lista',
  terminateReasonBlock: 'Uppsägning pga bospärr',
  moveOutType: 'Avflyttningstyp',
  edit: 'Redigera',
  send: 'Skicka',
  confirmAndSend: 'Bekräfta och skicka',
  createprint: 'Skapa utskrift',
  print: 'Skriv ut',
  save: 'Spara',
  delete: 'Ta bort',
  undo: 'Ångra',
  deleteChecked: 'Ta bort markerade',
  editResidentialLimit: 'Ändra bospärrdatum',
  editResidentialLimitBlock:
    'Den nya bospärren måste sättas senare än aktuell bospärr men högst två år efter. Datumet skall vara den sista i vaje månad.',
  sendMessage: 'Skicka meddelande',
  messageMeWhenReady: 'Meddela mig när beställning är klar',
  contactCustomer: 'Kontakta kund',
  message: 'Meddelande',
  month: 'Månad',
  currentmonth: 'Aktuell månad',
  ocr: 'OCR',
  customercontacts: 'Kontakttillfällen',
  savecustomercontact: 'Spara kontakttillfälle',
  textmessage: 'SMS',
  and: 'och',
  messagePrintedBlock: 'Du har skrivit ut meddelandet',
  yes: 'Ja',
  no: 'Nej',
  space: 'Utrymme',
  detail: 'Detalj',
  note: 'Notering',
  remark: 'Anmärkning',
  revisions: 'Revisioner',
  value: 'Värde',
  close: 'Stäng',
  modifiedBy: 'Ändrat av',
  moveincode: 'Inflyttningskod',
  searchCustomer: 'Sök kund (> 5 tecken)',
  lastAnswerdate: 'Senast svarsdatum',
  createContract: 'Skapa nytt avtal',
  deleteContractQuestion: 'Vill du verkligen makulera kontraktet?',
  deleteContract: 'Makulera avtal',
  pdf: 'PDF',
  createPDF: 'Skapa PDF',
  deleteAddressQuestion: 'Vill du verkligen ta bort adressen?',
  deleteCheckQuestion: 'Vill du verkligen ta bort kontrollen?',
  deleteRestrictionQuestion: 'Vill du verkligen ta bort kundspärren?',
  deleteSublettingQuestion: 'Vill du verkligen ta bort andrahandsansökan?',
  deleteAccessQuestion: 'Vill ta bort tillträdet från listan?',
  deleteProcessInstanceQuestion: 'Vill du verkligen ta bort ärendet?',
  deleteMarkedProcessInstancesQuestion: 'Vill du verkligen ta bort alla markerade ärenden?',
  deleteSubletting: 'Ta bort andrahandsansökan',
  deleteCheck: 'Ta bort kontroll',
  rentalInvoiced: 'Aviserat',
  resultCause: 'Statuskommentar',
  debitAmount: 'Debiterat',
  payedAmount: 'Betalt',
  balance: 'Saldo',
  paymentReceived: 'AF Bostäder tillhanda',
  paymentLateDays: 'Sen dag',
  notes: 'Anteckningar',
  rentalSpecification: 'Hyresspecifikation',
  invoiceSpecification: 'Fakturaspecifikation',
  for: 'för',
  invoicePDF: 'Faktura PDF',
  statementPDF: 'Kontoutdrag',
  rentpart: 'Hyrestillägg',
  amount: 'Belopp',
  rentBalance: 'Hyressaldo',
  invoiceBalance: 'Fakturasaldo',
  notInvoiced: 'ej aviserad',
  invoiceNumber: 'Fakturanummer',
  invoicedAmount: 'Fakturerat',
  dueDate: 'Förfallodag',
  article: 'Artikel',
  openUploadFile: 'Öppna uppladdad fil',
  createRentalRestriction: 'Skapa ny uthyrningsspärr',
  terminateContract: 'Säg upp avtal',
  cancelTerminateContract: 'Återtag uppsägning',
  ledger: 'Reskontra',
  invoices: 'Fakturor',
  checks: 'Kontroller',
  customerrestrictions: 'Kundspärrar',
  productContract: 'Produkter/Avtal',
  keys: 'Nycklar',
  system: 'System',
  keylabel: 'Stämpling',
  keyserial: 'Löpnummer',
  flex: 'Flexläge',
  createNewKey: 'Skapa ny nyckel',
  editKey: 'Redigera nyckel',
  deleteKey: 'Ta bort nyckel',
  cases: 'Serviceordrar',
  transactions: 'Transaktioner',
  latestInspectionStatus: 'Senaste besiktningsstatus',
  maintenanceStatus: 'Underhållsstatus',
  handle: 'Behandla',
  buildingspace: 'Utrymme',
  performedBy: 'Utfört av',
  measureDate: 'Utförandedatum',
  registerMeasureNoOrder: 'Registrera åtgärd utan serviceorder',
  equipment: 'Utrustning',
  add: 'Lägg till',
  clear: 'Rensa',
  clearImages: 'Rensa bilder',
  deleteKeyQuestion: 'Vill du verkligen ta bort nyckeln?',
  keynoteHeader: 'Nyckelnotering',
  refresh: 'Uppdatera',
  employee: 'Medarbetare',
  info: 'Info',
  assignee: 'Ansvarig',
  deleteReservationsQuestion: 'Vill du verkligen ta bort alla bokningar för denna produkt?',
  deleteReservationsConfirmation: 'Alla produktens bokningar ha nu tagits bort!',
  deleteAllReservations: 'Avboka alla',
  reservation: 'Bokning',
  applicant: 'Sökande',
  whoshows: 'Visning',
  whoshowsPhone: 'Telefon',
  proposalStore: 'Avtalsförslag förråd',
  productInfo: 'Uppgift om produkt',
  doorName: 'Namnskylt',
  contractProposal: 'Avtalsförslag',
  signContract: 'Signera avtal',
  editAnswerDate: 'Ändra svarsdatum',
  checkList: 'Checklista',
  store: 'Förråd',
  report: 'Återrapportera',
  reported: 'Besiktningsprotokollet har återrapporterats',
  cleaningProtocol: 'Skriv ut städprotokoll',
  cleaningProtocolQuestion: 'Vill du skriva ut städprotokollet?',
  pricelist: 'Prislista',
  publishProduct: 'Publicera produkt',
  publish: 'Publicera på webb',
  'publish.type.true': 'Ja',
  'publish.type.false': 'Nej',
  'product.test.true': 'OBS! Testprodukt',
  'product.test.false': 'Ej testprodukt',
  'exemption.active.true': 'Ja',
  'exemption.active.false': 'Nej',
  created: 'Skapad',
  changed: 'Senast ändrad',
  changedBy: 'Senast ändrad av',
  createdBy: 'Skapad av',
  startProcess: 'Starta ärende',
  done: 'Klart',
  moreInfo: 'Mer information',
  area: 'Område',
  areas: 'Områden',
  periodYYYYMM: 'Period åååå-mm',
  errorReport: 'Felanmälan',
  performer: 'Utförare',
  select: 'Välj',
  filter: 'Filter',
  activities: 'Aktiviteter',
  attachFile: 'Bifoga fil',
  attachExcelFile: 'Bifoga excelfil',
  chooseFile: 'Välj fil',
  chooseCustomer: 'Välj kund',
  noFileChosen: 'Ingen fil har valts',
  freeTextFilter: 'Fritext filter',
  searchresult: 'Sökresultat',
  contractPdf: 'Avtal PDF',
  validationSubletLength: 'Perioden måste vara minst 1 och högst 12 månader lång',
  validationSubletDay: 'Perioden måste börja den första och sluta den sista i en månad',
  sqrMtrsUnit: 'kvm',
  moneyUnit: 'kr',
  conditions: 'Villkor',
  addArticle: 'Välj en artikel som ska läggas till som en ny fakturarad',
  addRentpart: 'Välj ett hyrestillägg som ska läggas till som en ny rad',
  createInvoiceForCustomer: 'Skapa faktura till',
  previewMessage: 'Förhandsgranska meddelande',
  hide: 'Dölj',
  numberOfProcessInstances: 'Antal ärenden',
  undoDelete: 'Ångra ta bort',
  allKeysAreIn: 'Alla nycklar inne',
  history: 'Historik',
  assign: 'Ta hand om/Tilldela',
  assignToMe: 'Ta hand om',
  deleteRentalRestrictionQuestion: 'Vill du verkligen ta bort uthyrningsspärren?',
  deleteRentalRestriction: 'Ta bort uthyrningsspärr',
  oldPassword: 'Nuvarande lösenord',
  newPassword: 'Nytt lösenord',
  confirmNewPassword: 'Bekräfta nytt lösenord',
  changePassword: 'Ändra lösenord',
  passwordIsChanged: 'Ditt lösenord är ändrat',
  takePicture: 'Ta bild',
  until: 'Tom',
  processInstanceId: 'Ärendenummer',
  count: 'Antal',
  excel: 'Excel',
  reload: 'Ladda om',
  reloadMessage: 'Var god ladda om sidan',
  sendReminder: 'Skicka påminnelse',
  sendReminderQuestion: 'Vill du skicka påminnelse?',
  chunkloadErrorMessage:
    'Det finns en nyare version av Diremo. Vänligen töm cachen och gör refresh på sidan för att läsa in den',
  concurrentUpdateErrorMessage: 'Systemet försöker uppdatera data som är upptagen, försök igen',
  deleteAttachmentQuestion: 'Vill du verkligen ta bort den bifogade filen?',
  favourites: {
    mark: 'Markera som favorit',
    unmark: 'Ta bort favoritmarkering',
    favourites: 'Visa bara favoriter',
    all: 'Visa alla',
  },
  sync: 'Synkronisera',
  syncingImages: 'Synkroniserar bilder',
  offlineList: 'Gå till offlinebesiktningar',
  offlineSave: 'Spara offline',
  offlineAllreadySaved: 'Redan sparad offline',
  offLineInspectionModalText:
    'Denna besiktning är sparad lokalt i listan off-line besiktningar. Vill du synkronisera och fortsätta fortsätta on-line?',
  offLineStatus: {
    new: 'Ej påbörjad',
    started: 'Påbörjad',
    reported: 'Återrapporterad',
  },
  offLineCleaning: {
    nocleaning: 'Ingen städning',
    cleaning: 'Städning',
    print: 'Städprotokoll',
  },
  noStorageLeft: 'Lagringsytan för offlinebilder är full. Synkronisera för att frigöra minne.',
  download: 'Ladda ner',
  downloadToExcel: 'Ladda ner till Excel',
  substitute: 'Vikarie',
  substitutesNew: 'Registrera ny vikarie',
  currentSubstitutes: 'Befintliga vikarier',
  todateAfterFromdate: 'Tom datum måste vara efter from datum',
  todateNotBeforeFromdate: 'Tom datum får inte vara före from datum',
  todateNotBeforeToday: 'Tom datum får inte vara innan idag',
  fromdateBefore7Days: 'From-datum måste vara minst 7 dagar framåt i tiden',
  areYouSure: 'Är du säker på att du vill göra detta?',
  substituteRemovalNote:
    'Observera att inga ombokningar eller ändring av anvarig bovärd på besiktningar görs. Du måste gå igenom och kontrollera om det finns ärenden på aktuell vikarie manuellt.',
  substituteCreateNote:
    'Eventuella besiktningar som skall utföras under vikarieperioden kommer att byta ansvarig bovärd till vald vikarie.',
  substitutePeriod: 'Vikarieperiod',
  noSubstitutes: 'Du har inga vikarier',
  communication: {
    name: 'Namn på meddelande',
    subject: 'Ämnesrad',
    publication: 'Publikation',
    selectRecipients: 'Urval av mottagare',
    includeSublets: 'Inkludera andrahandshyresgäster',
    getRecipients: 'Hämta mottagare',
    recipients: 'Mottagare',
    createMessages: 'Skapa meddelanden',
    excel: 'Excel',
    goToBizwizard: 'Gå direkt till Bizwizard',
  },
  access: 'Tillträde',
  accessList: 'Tillträdeslista',
  all: 'Alla',
  allAreas: 'Alla områden',
  projectLabels: {
    access: 'Tillträde',
    choosePhase: 'Välj etapp',
    connectPhase: 'Koppla till etapp',
    connectedMessage: 'Produkterna är nu kopplade till projektet',
    project: 'Project',
    projectPhase: 'Etapp',
    reason: 'Orsak',
    accessrequests: 'Begärda tillträden',
    newAccessrequest: 'Begär nytt tillträde',
    sendAccessreques: 'Skicka tillträde',
    firstReminder: 'Första påminnelse',
    numberOfReminders: 'Antal påminnelser',
    dayBetweenReminders: 'Dagar mellan påminnelser',
    otheraddresses: 'Kopia på tillträdesmail till',
    editSwedishText: 'Svensk text',
    editEnglishText: 'Engelsk text',
    createMessage: 'Skapa meddelande',
    selectProducts: 'Urval valda bostäder',
  },
  apartment: 'Lägenhet',
  corridor: 'Korridorrum',
  commercial: 'Lokal',
  syncErrorMessage:
    'En eller flera besiktningar är redan återrapporterade och kan därför inte synkroniseras. Radera den eller de besiktningar som har synkroniseringsproblem och försök igen.',
  product_id: 'Produkt ID',
  customer_id: 'Kund ID',
  contract_id: 'Avtal ID',
  color: 'Färg',
  symbol: 'Ikon',
  processid: 'Process',
  taskid: 'Aktivitet',
  taskattribute: 'Aktivitetsegenskaper',
  taskattributes: 'Aktivitetsegenskaper',
  substituteperiod: 'Vikarieperiod',
  addsubstituteinfo:
    'Eventuella besiktningar som skall utföras under vikarieperioden kommer att byta ansvarig bovärd till vald vikarie.',
  deletesubstituteinfo:
    'Observera att inga ombokningar eller ändring av ansvarig bovärd på besiktningar görs. Du måste gå igenom och kontrollera om det finns ärenden på aktuell vikarie manuellt.',
  none: 'Ingen',
  projectpoints: 'Nyproduktionsfaktor',
  areasize: 'Storlek - Område',
  areasizes: 'Storlek - Område',
  accessrequest: 'Begärt tillträde',
  accessrequests: 'Begärda tillträden',
  accessstate: 'Status',
  account: 'Konto',
  accounting: 'Bokföring',
  accountingbooked: 'Visma',
  accountingactivitys: 'Bokföringsaktiviteter',
  accountingactivity: 'Bokföringsaktivitet',
  accountingreport: 'Bokföringsunderlag',
  accountingreports: 'Bokföringsunderlag',
  accountingroutine: 'Bokföringsrutin',
  'accountingreport.status.new': 'Ny',
  'accountingreport.status.printed': 'Utskriven',
  'accountingreport.status.archived': 'Arkiverad',
  'accountingreport.status.booked': 'Bokförd',
  accountdetail: 'Kontering',
  accountdetails: 'Konteringar',
  accountlocation: 'Var',
  actions: 'Åtgärder',
  adjust: 'Justera',
  adjusting: 'Hyresändrar',
  adjustedby: 'Hyresändrad av',
  adjustedtime: 'Hyresändring utförd',
  adjustmenttype: 'Hyresändringsstatus',
  adjustmentroutine: 'Hyresändringsrutin',
  adjustmentindex: 'Indexändring',
  adjustmentannual: 'Årlig höjning',
  adjustmentreport: 'Hyresändringslista',
  adjustmentaction: 'Uppdatera hyror',
  adjustmentexcel: 'Läs in hyror från Excel',
  adjustmentactiontext: 'Genomför hyresändring enligt hyresändringslista',
  active: 'Aktiv',
  activitiprocess: 'Processbeskrivning',
  activitys: 'Aktiviteter',
  activity: 'Aktivitet',
  administrator: 'Administratör',
  adjustmentrentpart: 'Hyrestillägg för hyresändring',
  adjustmentrentparts: 'Hyrestillägg för hyresändring',
  alternative: 'Alternativ',
  annualincrease: 'Hyreshöjning/år',
  applicantnottenant: 'Endast sökande som inte är boende',
  approveddate: 'Godkänd datum',
  approved: 'Godkänn',
  archive: 'Arkivera',
  articlenumber: 'Artikelnummer',
  areatype: 'Områdestyp',
  areaname: 'Områdesnamn',
  areaName: 'Område',
  areaemployee: 'Område - Medarbetare',
  areaemployees: 'Område - Medarbetare',
  substitutes: 'Vikarier',
  areabuildings: 'Område - Byggnad',
  areabuilding: 'Område - Byggnad',
  areamaintobjects: 'Område - Fastighetsobjekt',
  areamaintobject: 'Område - Fastighetsobjekt',
  areaproducts: 'Produkt - Område',
  areaproduct: 'Produkt - Område',
  admissionyear: 'Antagningsår',
  admissionterm: 'Antagningstermin',
  age: 'Ålder',
  allowedmoveoutdate: 'Tidigaste uppsägningsdatum',
  allvacancyreport: 'Rapport outhyrt',
  apartmentnumber: 'Lägenhetsnummer',
  apartmentlabel: 'Lägenhetsbeteckning',
  applicants: 'Sökande',
  autogirogenerate: 'Autogiro-inläsning',
  autogiroregistry: 'Autogiro-anmälan',
  autogirostatus: 'Autogiro-fil',
  badge: 'Not',
  baseindex: 'Basindex',
  billed: 'Aviserat',
  billing: 'Avisering',
  billingcontracts: 'Avier',
  billingdifference: 'Avvikelse-avisering',
  billingmanual: 'Manuell avisering',
  billingregistry: 'E-fakturaanmälningar',
  billingreport: 'Aviseringslista',
  billingreports: 'Aviseringslistor',
  'billingreport.autogirostatus.none': 'Ingen',
  'billingreport.autogirostatus.notcreated': 'Fil ej skapad',
  'billingreport.autogirostatus.created': 'Fil skapad',
  'billingreport.efakturastatus.none': 'Ingen',
  'billingreport.efakturastatus.notcreated': 'Fil ej skapad',
  'billingreport.efakturastatus.created': 'Fil skapad',
  'billingreport.status.new': 'Ny',
  'billingreport.status.printed': 'Utskriven',
  'billingreport.status.published': 'Publicerad',
  'billingreport.status.sent': 'Skickad',
  'billingreport.status.sentwithfails': 'Skickad men fel',
  billingroutine: 'Aviseringsrutin',
  ocrgenerate: 'BGMAX-inläsning',
  blueprint: 'Ritning',
  bodysw: 'Svensk text',
  bodyen: 'Engelsk text',
  book: 'Uppdatera',
  bookedamount: 'Bokfört belopp',
  bookingdate: 'Bokföringsdatum',
  browse: 'Sök fil',
  budgetyear: 'Budgeterat till år',
  budget: 'Budget',
  budgettypeProperty: 'Fastighetsunderhåll',
  budgettypeJanitorial: 'Skötseluppgifter',
  bwhtmlcontent: 'Innehåll',
  budgetdate: 'Budgeterat till',
  building: 'Byggnad',
  'building.type.building': 'Byggnad',
  'building.type.land': 'Markyta',
  'building.type.complement': 'Komplementsbyggnad',
  'building.type.common': 'Generell',
  buildingnumber: 'Byggnadsnummer',
  buildings: 'Byggnader',
  buildingpart: 'Byggdel',
  buildingparts: 'Byggdelar',
  buildingparttype: 'Byggdelstyp',
  buildingparttypes: 'Byggdelstyper',
  buildingspaceid: 'Utrymme id',
  buildingspaces: 'Utrymme',
  buildingspaceDescription: 'Utrymme',
  buildingspacetypes: 'Utrymmestyper',
  buildingspacetype: 'Utrymmestyp',
  buildstats: 'Bygg-statistik',
  buildyear: 'Byggår',
  bwtemplate: 'Bizwizard Mall',
  component: 'Komponent',
  components: 'Komponenter',
  componenttype: 'Komponenttyp',
  componenttypes: 'Komponenttyper',
  corridorcontactfrom: 'Korridorkontakt from',
  corridorcontactto: 'Korridorkontakt tom',
  causeid: 'Dispensorsak-id',
  causeofnotice: 'Uppsägningsorsak',
  certificatetype: 'Ärende',
  check: 'Kontrollera',
  checkcustomers: 'Kontrollera kunder',
  child: 'Barn',
  children: 'Barn',
  childrens: 'Barn',
  classname: 'Javaklass',
  clearroutine: 'Rensningsrutin',
  clearreport: 'Rensningar',
  clearreports: 'Rensningar',
  coaddress: 'Co-adress',
  countnote: 'Ändringsnotering mängd',
  customeraddress: 'Kundadress',
  customeraddresse: 'Kundadress',
  customeraddresses: 'Kundadresser',
  customercontacttype: 'Kontakttillfälle typ',
  customerrestrictiontype: 'Kundspärr typ',
  customername: 'Kund namn',
  collectionroutine: 'Inkassorutin',
  control: 'Kontroll',
  copy: 'Kopiera',
  invoicecollectioncreate: 'Skapa inkassounderlag',
  invoicecollectionroutine: 'Krav/Inkassorutin Faktura',
  invoicecollections: 'Inkassounderlag',
  collectionstatus: 'Inkassostatus',
  complement: 'Ändra',
  completeddate: 'Klart datum',
  completedtime: 'Klart',
  condition: 'Villkor Sve',
  conditionen: 'Villkor Eng',
  confirmationcodetenant: 'Kvittens hyresgäst',
  confirmationcodelandlord: 'Kvittens hyresvärd',
  content: 'Innehåll',
  contractid: 'Avtal id',
  contractnumber: 'Avtalsnummer',
  'contract.movein.movein': 'Nytt avtal',
  'contract.movein.relocation': 'Omflyttning',
  'contract.movein.transfer': 'Överlåtelse',
  'contract.movein.null': 'Ingen',
  'contract.moveout.moveout': 'Avflyttning',
  'contract.moveout.eviction': 'Uppsägning',
  'contract.moveout.transfer': 'Överlåtelse',
  'contract.moveout.death': 'Dödsfall',
  'contract.moveout.relocation': 'Omflyttning',
  'contract.moveout.null': 'Ingen',
  'contract.moveout.residentiallimit': 'Bospärr',
  'contract.moveout.studycontrol': 'Studiekontroll',
  'contract.moveout.demolition': 'Rivning',
  'contract.moveout.unauthorizedsecondary': 'Otillåten andrahand/dubbelboende',
  'contract.nextmoveinstatus.notready': 'Ej klar för inflyttning',
  'contract.nextmoveinstatus.ready': 'Klar för inflyttning',
  'contract.nextmoveinstatus.cleaning': 'Städning',
  'contract.keystatus.customer': 'Kund',
  'contract.keystatus.landlord': 'Servicecenter',
  'contract.keystatus.nextcustomer': 'Nästa kund',
  'contract.status.none': 'Ingen',
  'contract.status.proposal': 'Avtalsförslag',
  'contract.status.signedbytenant': 'Signerat av hyresgäst',
  'contract.status.signedbylandlord': 'Signerat av hyresvärd',
  'contract.status.noticed': 'Uppsagt av hyresgäst',
  'contract.status.terminated': 'Uppsagt och bekräftat',
  'contract.type.housing': 'Bostadsavtal',
  'contract.type.storage': 'Förrådsavtal',
  'contract.type.parking': 'Bilplatsavtal',
  'contract.type.commercial': 'Lokalavtal',
  'contract.type.demolition': 'Tidsbegränsat avtal',
  'customer.type.resident': 'Boende',
  'customer.type.housingapplicant': 'Sökande',
  'customer.type.secondarytenant': 'Andrahandshyresgäst',
  'customer.type.inactive': 'Inaktiv',
  counterpart: 'Motpart',
  contracttype: 'Avtalstyp',
  contracts: 'Hyresavtal',
  contractconditions: 'Särskilda villkor, avtal',
  contractcondition: 'Särskilda villkor, avtal',
  contractconditionsen: 'Särskilda villkor Eng, avtal',
  contractconditionen: 'Särskilda villkor Eng, avtal',
  contract_number: 'Avtalsnummer',
  contractrent: 'Avtalsbunden hyra',
  contractrents: 'Avtalsbundna hyror',
  contractor: 'Entreprenör',
  counter: 'Antal',
  createcontact: 'Skapa kontakttillfälle',
  'createcontact.type.customer': 'Endast på kund',
  'createcontact.type.customerandproduct': 'På kund och produkt',
  'createcontact.type.none': 'Skapa inte kontakttillfälle',
  customernumber: 'Kundnummer',
  customerid: 'Kund id',
  customerinfo: 'Kundinformation',
  customercontact: 'Kontakttillfälle',
  customerinvoice: 'Kundfaktura',
  'customerinvoice.type.rental': 'Hyresfaktura',
  'customerinvoice.type.miscinvoice': 'Diversefaktura',
  'customerinvoice.type.creditinvoice': 'Kreditfaktura',
  'customerinvoice.type.vacant': 'Vakansfaktura',
  'customerinvoice.type.manual': 'Manuell hyresfaktura',
  'customerinvoice.type.manualdebt': 'Manuell restfaktura',
  'customerinvoice.type.reminder': 'Påminnelsefaktura',
  'customerinvoice.status.new': 'Ny',
  'customerinvoice.status.published': 'Publicerad',
  'customerinvoice.status.printed': 'Utskriven',
  'customerinvoice.status.sent': 'Skickad',
  'customerinvoice.status.sentwithfails': 'Kunde ej skickas',
  'customerinvoice.status.booked': 'Bokförd',
  'customerinvoice.status.manual': 'Manuellt',
  'customerinvoice.status.approved': 'Godkänd',
  'customerinvoice.collectionstatus.notsent': 'Ej Skickad',
  'customerinvoice.collectionstatus.sent': 'Skickad',
  customerinvoices: 'Kundfakturor',
  customerinvoiceid: 'Kundfaktura id',
  customerinvoicetype: 'Kundfakturatyp',
  customerchecks: 'Kundkontroller',
  customerchecktype: 'Kundkontrolltyp',
  customercheck: 'Kundkontroll',
  customerinvoicerow: 'Fakturarad',
  customerinvoicerows: 'Fakturarader',
  customerinvoicereport: 'Debiteringslista',
  customerinvoicereports: 'Debiteringslistor',
  'customerinvoicereport.status.new': 'Ny',
  'customerinvoicereport.status.printed': 'Utskriven',
  'customerinvoicereport.status.archived': 'Arkiverad',
  'customerinvoicereport.status.booked': 'Bokförd',
  'customerinvoicereport.status.sent': 'Skickad',
  'customerinvoicereport.status.partlysent': 'Delvis skickad',
  'customerinvoicereport.status.sentwithfails': 'Skickad men fel',
  'customerinvoicereport.status.unknown': 'Problem med meddelandetjänst',
  'customerinvoicereport.type.rental': 'Hyresfakturor',
  'customerinvoicereport.type.misc': 'Diversefakturor',
  'customerinvoicereport.type.credit': 'Kreditfakturor',
  'customerinvoicereport.type.vacant': 'Vakansfakturor',
  'customerinvoicereport.type.manual': 'Manuella hyresfakturor',
  'customerinvoicereport.type.reminder': 'Påminnelsefakturor',
  customerpayment: 'Inbetalning',
  customerpayments: 'Inbetalningar',
  customerpaymentreport: 'Inbetalningslista',
  customerpaymentreports: 'Inbetalningslistor',
  'customerpayment.status.new': 'Ny',
  'customerpayment.status.printed': 'Utskriven',
  'customerpayment.status.booked': 'Bokförd',
  'customerpayment.type.ag': 'Autogiro',
  'customerpayment.type.bankfee': 'Bankavgift',
  'customerpayment.type.ocr': 'Bg Max',
  'customerpayment.type.camt054': 'camt.054',
  'customerpayment.type.excel': 'Excel',
  'customerpayment.type.bgmanual': 'BG Manuell',
  'customerpayment.type.payrolldeduct': 'Hyra avdrag från lön',
  'customerpayment.type.rentalloss': 'Hyresförlust',
  'customerpayment.type.rentalcollection': 'Inbetalning Profina',
  'customerpayment.type.adjustmentreminderfee': 'Justering påminnelseavgift',
  'customerpayment.type.certainrentalloss': 'Konstaterad hyresförlust',
  'customerpayment.type.notidentified': 'Oidentifierad betalning',
  'customerpayment.type.adjustmentuncertainloss': 'Osäker fordran',
  'customerpayment.type.adjustmentcustomerloss': 'Kundförlust',
  'customerpayment.type.adjustmentcredit': 'Justering tillgodon',
  'customerpayment.type.creditinvoice': 'Justering kreditfaktura',
  'customerpayment.type.uncertainrentalloss': 'Osäker hyresfordran',
  'customerpayment.type.profinainterest': 'Ränta Profina',
  'customerpayment.type.profinacost': 'Kostnad Profina',
  'customerpayment.type.rounding': 'Öresutjämning',
  'customerpayment.type.cash': 'Kassa',
  'customerpaymentreport.status.new': 'Ny',
  'customerpaymentreport.status.printed': 'Utskriven',
  'customerpaymentreport.status.booked': 'Bokförd',
  'customerpaymentreport.status.archived': 'Arkiverad',
  customerRef: 'Kund',
  customerrestriction: 'Kundspärr',
  code: 'Kod',
  county: 'Län',
  cost: 'Kostnad',
  costcenter: 'Kostnadsställe',
  createddate: 'Skapad datum',
  createcases: 'Studiekontroll, skapa Ärenden',
  customers: 'Kunder',
  customerpaymenttype: 'Betalningssätt',
  credit: 'Kreditkontering',
  duedate: 'Förfallodatum',
  duedate1: 'Klart datum',
  startdaysbefore: 'Antal dagar innan klart för start av ärende',
  reminderdaysbefore: 'Antal dagar innan klart för påminnelse',
  nextstartdate: 'Nästa startdatum',
  nextreminderdate: 'Nästa påminnelse',
  nextduedate: 'Nästa klartdatum',
  dateofnotice: 'Uppsagt datum',
  dateuntil: 'Datum och äldre',
  days: 'Dagar',
  daysbetweenreminders: 'Dagar mellan påminnelser',
  dayslate: 'Sen dag',
  debitroutine: 'Debiteringsrutin',
  debit: 'Debitera',
  debiting: 'Debitering',
  debitingrows: 'Debiteringsrader',
  debitmonthly: 'Månadsdebitering',
  debitmanual: 'Manuell debitering ',
  deface: 'Makulera',
  timebounddate: 'Tidsbegränsat avtal tom',
  demolitiondate: 'Upphör datum',
  demolition: 'Tidsbegränsat avtal',
  demolitioncontract: 'Tidsbegränsat avtal',
  demolitioncontracts: 'Tidsbegränsade avtal',
  descriptionOrMesuredescriptionDescription: 'Åtgärd',
  descriptionsw: 'Beskrivning (Sve)',
  descriptionen: 'Beskrivning (Eng)',
  description1: 'Beskrivning 1',
  description2: 'Beskrivning 2',
  description3: 'Beskrivning 3',
  description4: 'Beskrivning 4',
  descriptionalternatives: 'Beskrivningsalternativ',
  descriptionalternative: 'Beskrivningsalternativ',
  descriptiongroups: 'Produktbeskrivning',
  descriptiongroup: 'Produktbeskrivning',
  detailDescription: 'Detalj',
  detailid: 'Detalj id',
  details: 'Detalj',
  detailtypeid: 'Detaljtyp id',
  detailtype: 'Detaljtyp',
  detailtypes: 'Detaljtyper',
  'detailtype.inspection.true': 'Skall besiktas',
  'detailtype.inspection.false': 'Besiktas ej',
  'detailtype.errorreport.true': 'Kan felanmälas',
  'detailtype.errorreport.false': 'Kan inte felanmälas',
  'detailtype.type.detail': 'Detalj',
  'detailtype.type.cleaning': 'Städbesiktning',
  'detailtype.type.checklist': 'Egenkontroll',
  descriptionAndAddress: 'Beskrivning och adress',
  descriptionOrType: 'Beskrivning eller typ',
  descriptiontext: 'Viktig information om bostaden',
  descriptiontextEn: 'Viktig information om bostaden (Engelsk)',
  devices: 'Fast inredning',
  device: 'Fast inredning',
  'device.type.fanroom': 'Fläktrum',
  'device.type.electricalcabinet': 'Elskåp',
  distribution: 'Distributionssätt',
  'distribution.type.email': 'E-post',
  'distribution.type.text': 'SMS',
  'distribution.type.emailandtext': 'E-post och SMS',
  'distribution.type.print': 'Utskrift',
  'distribution.type.bizwizard': 'Bizwizard',
  documentation: 'Dokumentation',
  documentations: 'Dokumentation',
  field: 'Fält',
  entity: 'Register',
  routine: 'Rutin',
  documentationtext: 'Text',
  'documentation.type.field': 'Fält',
  'documentation.type.entity': 'Register',
  'documentation.type.routine': 'Rutin',
  'label.color.default': 'Sekundär',
  'label.color.primary': 'Primär',
  'label.color.success': 'Framgång',
  'label.color.info': 'Info',
  'label.color.warning': 'Varning',
  'label.color.danger': 'Fara',
  doorname: 'Dörrskyltsnamn',
  duetime: 'Planerat klart datum',
  earlymoveinsent: 'Tidigare infl. meddelande skickat',
  permissionsigned: 'Tidigare infl. medgivande signerat',
  permissionapproved: 'Tidigare infl. ansvar övertaget',
  efakturaregistry: 'Efaktura-anmälan',
  efakturastatus: 'Efaktura-fil',
  employeeid: 'Medarbetare id',
  employees: 'Medarbetare',
  employeename: 'Medarbetare',
  employeeemployeerole: 'Medarbetare - Medarbetarroll',
  employeeemployeeroles: 'Medarbetare - Medarbetarroller',
  employeeroles: 'Medarbetarroller',
  employeerole: 'Medarbetarroll',
  employeeroledescription: 'Medarbetarroll',
  entitymodifiedby: 'Senast ändrad av',
  entitymodifieddate: 'Senast ändrad',
  entitycreatedby: 'Skapad av',
  entitycreateddate: 'Skapad',
  entrance: 'Uppgång',
  errand: 'Ärende',
  errands: 'Ärenden',
  measureerrandtype: 'Åtgärdens ärendetyp',
  errorreportable: 'Felanmälan',
  exemption: 'Dispens',
  exemptionid: 'Dispens id',
  exemptionperiods: 'Dispensperiod',
  exemptionperiod: 'Dispensperiod',
  exemptionreasons: 'Dispensorsak',
  exemptionreason: 'Dispensorsak',
  exemptionuntil: 'Dispensdatum',
  exemptiondate1: 'Dispens datum 1',
  exemptiondate2: 'Dispens datum 2',
  exposure: 'Utsatthet',
  equipments: 'Utrustning',
  equipmentdetails: 'Utrustning - Detalj',
  event: 'Händelse som initierar utskicket',
  fromcontractdate: 'Från avtalsdatum',
  tocontractdate: 'Till avtalsdatum',
  fromrole: 'Från vem',
  torole: 'Till vem (roll)',
  fromyear: 'Från år',
  toyear: 'Till år',
  fromqueuedate: 'Från ködatum',
  toqueuedate: 'Till ködatum',
  frequency: 'Frekvens',
  files: 'Filer',
  firstmoveindate: 'Första inflyttningsdag',
  firstreminderdate: 'Första påminnelse',
  generation: 'Generation',
  filtercustomers: 'Väljer ut kunder',
  gironumber: 'Gironummer',
  group: 'Grupp',
  hrefsw: 'Länk (Sve)',
  hrefen: 'Länk (Eng)',
  importing: 'Läser in',
  importantbeforevisitsw: 'Viktigt inför besöket (Sve)',
  importantbeforevisiten: 'Viktigt inför besöket (Eng)',
  includedinrent: 'Spec på avin',
  indexpart: 'Indexandel %',
  indexclausetype: 'Indexklausul',
  'indexclause.type.decreaseallowed': 'Sänkning tillåten',
  'indexclause.type.decreasenotallowed': 'Sänkning ej tillåten',
  'indexclause.type.none': 'Ingen',
  initials: 'Signatur',
  inspectable: 'Besiktning',
  instruction: 'Instruktion',
  infolink: 'Länk till information (Sv)',
  infolinken: 'Länk till information (Eng)',
  information: 'Information',
  inspections: 'Besiktningar',
  inspectionid: 'Besiktning id',
  inspectiondetail: 'Besiktningsrad',
  inspectiondetails: 'Besiktningsrader',
  inspectioncharge: 'Besiktningsdebitering',
  inspectioncharges: 'Besiktningsdebiteringar',
  inspectiontime: 'Besiktningstid minuter',
  interval: 'Intervall',
  intervalnormal: 'Intervall normalt',
  intervalexposed: 'Intervall utsatt',
  intervalprotected: 'Intervall skyddat',
  investment: 'Investering',
  investmentpart: 'Investering',
  investmentpercent: 'Investering %',
  invoicedamount: 'Fakturerat',
  invoicedate: 'Fakturadatum',
  invoicenumber: 'Fakturanummer',
  invoicetype: 'Aviseringssätt',
  invoicemanualpayment: 'Inbetalning Faktura',
  printinvoice: 'Pappersfaktura',
  inovoicecollection: 'Inkassounderlag',
  invoicecollectioninvoicecreate: 'Skapa inkassounderlag',
  isCurrent: 'Aktuell',
  isNotCurrent: 'Ej aktuell',
  keydescription: 'Nyckelnotering',
  keystatus: 'Nyckelstatus',
  keysystem: 'System',
  labeling: 'Stämpling',
  lastinspectiondate: 'Senaste besiktning',
  lastinspectionnotes: 'Senaste besiktningsnotering',
  equipmentdescription: 'Utrustning',
  equipmentnumber: 'Utrustning nummer',
  'equipment.type.material': 'Material',
  'equipment.type.appliances': 'Vitvaror',
  'equipment.type.armature': 'Armaturer',
  'equipment.type.interior': 'Inredning',
  'equipment.type.furniture': 'Möbler',
  warrantytime: 'Garantitid',
  warrantytext: 'Garantitext',
  lastexecutedtime: 'Senast startad',
  inspectionstatus: 'Besiktningsstatus',
  lastinspectionstatus: 'Senaste besiktningsstatus',
  lastinspectionworker: 'Senaste besiktningsman',
  lastmaintenanceworker: 'Senaste utförare',
  lastmaintenancedescription: 'Senaste åtgärdssnotering',
  lastmaintenancemeasuredescription: 'Senaste åtgärdssnotering',
  lastmaintenancedate: 'Senaste underhåll',
  latestanswerdate: 'Senaste svarsdatum',
  ledgers: 'Reskontra',
  ledgerinfo: 'Reskontra',
  ledgerreport: 'Reskontrajournal Faktura',
  locations: 'Belägenhetsadresser',
  longPeriod: 'Månad År',
  logout: 'Logga ut',
  lottery: 'Lottning pågår',
  'lottery.type.true': 'Ja',
  'lottery.type.false': 'Nej',
  maintobjects: 'Fastighetsobjekt',
  maintobjectdescription: 'Fastighetsobjektstyp',
  maintobjectdescriptions: 'Fastighetsobjektstyper',
  maintobjectid: 'Fastighetsobjekt id',
  maintobjectkeys: 'Nycklar',
  maintobjecttypes: 'Fastighetsobjektstyper',
  maintobjecttype: 'Fastighetsobjektstyp',
  maintenancebudget: 'Budget',
  maintenanceexport: 'Export till Excel',
  maintenancereport: 'Återrapport utförda åtgärder',
  'maintenance.type.both': 'Båda',
  'maintenance.type.property': 'Fastighetsunderhåll',
  'maintenance.type.janitorial': 'Skötsel',
  manualpayment: 'Manuell betalning',
  manualplanning: 'Tillåt manuell omplanering',
  matched: 'Matchade',
  maxnumber: 'Max antal',
  mdchildrens: 'Relaterade typer',
  mdchildren: 'Relaterade typer',
  mdparents: 'Relaterad till typ',
  mdparent: 'Relaterad till typ',
  keychildrens: 'Relaterade nycklar',
  keychildren: 'Relaterade nycklar',
  keyparents: 'Relaterad till knippa',
  keyparent: 'Relaterad till knippa',
  messagelog: 'Meddelandelogg',
  measurelist: 'Åtgärdslista',
  measuredescription: 'Åtgärdstyp',
  measuredescriptions: 'Åtgärdstyper',
  measuredescriptionassociations: 'Åtgärdsrelationer',
  measuredescriptionassociation: 'Åtgärdsrelationer',
  measureaccessstatus: 'Tillträde status',
  messagebody: 'Innehåll',
  measureyear: 'Senaste åtgärd år',
  membercontrol: 'Medlemskontroll',
  membercontrol_tenants: 'Hyresgäster',
  membercontrol_applicants: 'Sökande',
  membercontrol_doc_tenants:
    '1) Ange ködatum fr o m - t o m\n2) Välj urval och nu sker en kontroll mot TRF\n3) När körningen är klar visas resultatet under fliken kunder\n4) Välj skicka mail = mail 1 skickas till alla som ej har en godkänd betalning, kontakttillfälle skapas på kund\n5) Notera urvalet (urval 1)\n6) Avvakta en tid och gör ett nytt urval (urval 2), använd samma urvalsparametrar som tidigare. Nu ska ett färre antal kunder fastna i kontrollen.\n7) Välj starta process och ärenden skapas för de kunder som ännu inte har betalat.',
  membercontrol_doc_applicants:
    '1) Ange avtalsdatum fr o m - t o m \n2) Välj urval och nu sker en kontroll av urvalet mot TRF\n3) Gör en överföring till excel över de bostadshyresgäster som inte har betalat\n4) Lämna över listan till bostadsombudsmannen för vidare hantering',
  method: 'Metod',
  selection: 'Urval',
  selectproducts: 'Välj bostäder',
  selectselectedproducts: 'Urval valda bostäder',
  senddate: 'Skicka tillträde',
  sendmail: 'Skicka mail',
  sendmailmessages: 'Skicka epost meddelanden',
  markInvoicesAsSent: 'Markera fakturor som skickade',
  startprocess: 'Starta process',
  startread: 'Starta inläsning',
  mailmessage: 'Meddelande',
  bizwizard: 'Bizwizard',
  minutespervisit: 'Minuter/besök',
  misc: 'Övrigt',
  miscinvoiceroutine: 'Faktureringsrutin',
  miscinvoice: 'Fakturaunderlag',
  miscinvoices: 'Fakturaunderlag',
  miscinvoicemanual: 'Manuell registrering',
  miscinvoicecredit: 'Kreditering',
  miscinvoicereports: 'Fakturalista',
  modifiedby: 'Senaste ändring av',
  modifieddate: 'Senaste ändring datum',
  moveoutdate: 'Avflyttningsdatum',
  moveindate: 'Inflyttningsdatum',
  moveintype: 'Inflyttningstyp',
  moveouttype: 'Avflyttningstyp',
  mm: 'MM',
  nameen: 'Namn (Eng)',
  numberofvisits: 'Antal besök',
  numberofproducts: 'Antal bostäder',
  numberofreminders: 'Antal påminnelser',
  nameAndDescription: 'Namn och beskrivning',
  nationality: 'Nationalitet',
  newfromdate: 'Ny hyra from',
  newamount: 'Ny hyra',
  newindex: 'Nytt index',
  nextrun: 'Nästa körning',
  nextmeasureyear: 'Nästa åtgärd enl intervall',
  nextmoveinstatus: 'Inflyttningsstatus nästa kund',
  nextearlymoveindate: 'Tidigare inflyttning nästa kund',
  nextexecutetime: 'Nästa körning',
  noanswer: 'Ej svar',
  notenant: 'Ingen hg',
  notification: 'Meddelande',
  noticedtodate: 'Uppsagt till datum',
  numberofkeys: 'Antal nycklar',
  noofunits: 'Antal',
  ocrnumber: 'Betalningsreferens',
  orgunits: 'Organisationsenheter',
  orgunit: 'Organisationsenhet',
  orgnumber: 'Organisationsnummer',
  orderdate: 'Fakturadatum',
  ordernumber: 'Beställningsnr',
  orderinfo: 'Hänvisning till ramavtal i beställning',
  otheraddresses: 'Kopia på tillträdesmail till',
  otherinfosw: 'Övrig information (Sve)',
  otherinfoen: 'Övrig information (Eng)',
  ourreference: 'Vår referens',
  ourreferencephone: 'Vår referens telefon',
  ourreferenceemail: 'Vår referens e-post',
  paidamount: 'Betalt',
  paiddate: 'Betaldatum',
  paidat: '',
  parametersettings: 'Parametersättning',
  parent: 'Förälder',
  parents: 'Förälder',
  paste: 'Klistra in',
  path: 'Sökväg',
  payeraddress: 'Betalare adress',
  payedamount: 'Betalt belopp',
  payfee: 'Avgift',
  payername: 'Betalare namn',
  paymentsproposal: 'Förslag på inbetalningar',
  paymentroutine: 'Inbetalningsrutin',
  payments: 'Inbetalningar',
  paymentdate: 'Betalningsdatum',
  paymentterm: 'Betalningsperiod',
  paymenttype: 'Betalningssätt',
  paymentcollections: 'Inkasso-betalningar',
  percent: 'Procent',
  periodicity: 'Hur ofta',
  periodicitynumber: 'Periodicitet antal',
  repeatvalue: 'Antal periodicitet',
  reorganizeledger: 'Sortera om reskontran',
  reorganizeledgerwarning:
    'OBS! Glöm inte boka om ev. hyra som flyttats mellan kostnadsställen. Klicka på OK för att sortera om reskontran på denna kund.',
  periodamount: 'Periodbelopp',
  periodrent: 'Periodhyra',
  periodsofnotice: 'Uppsägningstid',
  permission: 'Rättighet',
  permissions: 'Rättigheter',
  permissiondescription: 'Description',
  permissionuserroles: 'Rättigheter - Användarroller',
  permissionuserrole: 'Rättighet - Användarroll',
  personalnumber: 'Personnummer',
  personalcode: 'Personal code',
  plannedyear: 'Planerat till år',
  planneddate: 'Planerat till',
  points: 'Poäng',
  points1: 'Grundpoäng',
  basepoints: 'Grundpoäng',
  basepointssize: 'Grundpoäng storlek',
  adjustmentpoints: 'Justeringspoäng',
  preview: 'Förhandsgranska',
  pricelists: 'Prislista',
  'pricelist.type.repair': 'Skador & reparationer',
  'pricelist.type.keys': 'Cylinderbyten & nycklar',
  'pricelist.type.cleaning': 'Städning',
  'pricelist.type.rentcompensation': 'Skadestånd hyra',
  'pricelist.type.furniturecompensation': 'Skadestånd möbelhyra',
  'pricelist.type.other': 'Övrig debitering',
  price: 'Pris',
  printer: 'Skrivare',
  printed: 'Utskriven',
  printdate: 'Utskriven datum',
  prioritychangedescription: 'Orsak till ändring av förtur',
  processdescription: 'Processbeskrivning',
  processes: 'Processer',
  productname: 'Produkt',
  productconditions: 'Särskilda villkor, produkt',
  productconditioncondition: 'Särskilda villkor, produkt',
  productcondition: 'Särskilda villkor, produkt',
  productproductconditions: 'Produkter - Särskilda villkor, produkt',
  productproductcondition: 'Produkt - Särskilda villkor, produkt',
  productconditionsen: 'Särskilda villkor Eng, produkt',
  productconditionencondition: 'Särskilda villkor Eng, produkt',
  productconditionen: 'Särskilda villkor, produkt Eng',
  productdescriptions: 'Produktbeskrivning',
  productdescription: 'Produktbeskrivning',
  productid: 'Produkt id',
  productnumber: 'Produktnummer',
  productmaintobject: 'Produkt - Fastighetsobjekt',
  productmaintobjects: 'Produkt - Fastighetsobjekt',
  productlistexcel: 'Underlag hyresändring',
  productrentpartlistexcel: 'Underlag produktbundet',
  contractrentpartlistexcel: 'Underlag avtalsbundet',
  productsize: 'Storlek',
  productsizes: 'Storlekar',
  producttype: 'Produkttyp',
  productvariant: 'Produktvariant',
  productvariants: 'Produktvarianter',
  productvat: 'Momsregistrering',
  test: 'Testprodukt',
  producttest: 'Testprodukt',
  productkey: 'Nyckel',
  productkeys: 'Nycklar',
  productkeyName: 'Nyckel',
  project: 'Projekt',
  projects: 'Projekt',
  projectphase: 'Etapp',
  projectphases: 'Etapper',
  projectmanagers: 'Projektledare',
  productproject: 'Produkt - Projekt',
  productprojects: 'Produkt - Projekt',
  propertys: 'Fastigheter',
  propertymaintenance: 'Underhåll/Skötsel',
  proposalanswerdate: 'Senaste svarsdatum',
  publication: 'Publikation',
  purpose: 'Ändamål',
  rentalledgerreport: 'Avstämning hyror',
  respite: 'Anstånd hyra',
  invoicerespite: 'Anstånd faktura',
  quantity: 'st',
  queuedate: 'Ködatum',
  queuedatechangedescription: 'Orsak till ändring av ködatum',
  queueremovaldate: 'Avregistreringsdatum',
  queuestatus: 'Köstatus',
  registrationtype: 'Typ',
  registerhandling: 'Registerbehandling',
  reference: 'OCR-nummer',
  registrationroutine: 'Registreringsrutin',
  registrationcreate: 'Anmälningar-inläsning',
  relations: 'Relationer',
  remodellingyear: 'Ombyggnadsår',
  buildingyear: 'Byggnadsår',
  rentalamount: 'Debiterat',
  pointvalue: 'Poängvärde',
  rentaladjustment: 'Hyresändringstyp',
  rentaladjustmenttype: 'Hyresändringstyp',
  rentalcollection: 'Inkassounderlag',
  rentalcollectionSv: 'Inkasso SV',
  rentalcollectionEn: 'Inkasso EN',
  rentalcollectionroutine: 'Krav/Inkassorutin Hyra',
  rentalcollectioncreate: 'Skapa inkassounderlag',
  rentalcollectiongenerate: 'Inkasso-inläsning',
  rentalcomplement: 'Hyrestillägg',
  rentaladministration: 'Hyresadministration',
  rentalperiod: 'Period',
  rentalrestrictions: 'Uthyrningsspärr',
  rentalspec: 'Hyresspecifikation',
  rentalmanualpayment: 'Inbetalning Hyra',
  rents: 'Hyror',
  rentperiod: 'Hyresperiod',
  'rentpart.term.onetime': 'Engångsbelopp',
  'rentpart.term.month': 'Månad',
  'rentpart.term.quarter': 'Kvartal',
  'rentpart.term.halfyear': 'Halvår',
  'rentpart.term.year': 'År',
  'rentpart.term.student': '9 månadershyra',
  rentparts: 'Hyrestillägg',
  'rentpart.included.false': 'Specificeras',
  'rentpart.included.true': 'Läggs till hyran',
  'rentpart.vattype.false': 'Ingen moms',
  'rentpart.vattype.true': 'Moms',
  'rentpart.type.rent': 'Grundhyra',
  'rentpart.type.supplement': 'Tillägg',
  'rentpart.type.fee': 'Avgift',
  'rentpart.type.retroactive': 'Retroaktivt',
  reminderSv: 'Betalningspåminnelse SV',
  reminderEn: 'Betalningspåminnelse EN',
  remindercreate: 'Skapa påminnelse',
  reminderscreate: 'Skapa påminnelser',
  reminderdate: 'Påminnelsedatum',
  remindersentdate: 'Påminnelsedatum',
  reminderreport: 'Påminnelserapport',
  reminderreports: 'Påminnelserapporter',
  reminderfee: 'Påminnelseavgift',
  remove: 'Ta bort',
  replyto: 'Svar till',
  reportid: 'Rapport id',
  reportingroutine: 'Avstämning/rapporter',
  result: 'Status',
  resultcause: 'Statuskommentar',
  signature: 'Kvittens',
  signatures: 'Kvittenser',
  signaturetime: 'Signerat',
  'unit.type.hours': 'Timmar',
  'unit.type.meters': 'Löpmeter',
  'unit.type.units': 'Stycken',
  'unit.type.sqrmeters': 'Kvadratmeter',
  'unit.type.pair': 'Par',
  'unit.type.firstpair': 'Första par',
  document: 'Dokument',
  ipaddress: 'IP-adress',
  routinetype: 'Rutin',
  diremoservice: 'Tjänst',
  diremoservices: 'Tjänster',
  serviceparent: 'Förälder',
  servicechildren: 'Barn',
  permissiondiremoservice: 'Rättigheter - Tjänster',
  permissiondiremoservices: 'Rättigheter - Tjänster',
  permissionDiremoservice: 'Rättigheter - Tjänster',
  permissionDiremoservices: 'Rättigheter - Tjänster',
  PermissionDiremoservices: 'Rättigheter - Tjänster',
  permissionUserrole: 'Rättigheter - Användarroller',
  title: 'Titel',
  towith: 'Till/Med',
  tosek: 'Till kronor',
  updateplanned: 'Fastställ budget',
  withsek: 'Med kronor',
  withpercent: 'Med procent',
  queueremovalcausetype: 'Avregistreringsorsak',
  reservefromdate: 'Publicerad from',
  reserveuntildate: 'Publicerad tom',
  reset: 'Nollställ',
  residentiallimit: 'Bospärr',
  respiteuntil: 'Anståndsdatum hyror',
  invoicerespiteuntil: 'Anståndsdatum fakturor',
  reason: 'Orsak',
  reasonsw: 'Orsak (Sve)',
  reasonen: 'Orsak (Eng)',
  roles: 'Roller',
  role: 'Roll',
  rolloverperiod: 'Förlängningstid',
  searchfree: 'Fritext',
  secondarytenants: 'Andrahandshyresgäst',
  secondarytenant: 'Andrahandshyresgäst',
  selecting: 'Läser databas',
  serverstats: 'Server-statistik',
  series: 'Verserie',
  serialnumber: 'Löpnummer',
  serialnumberShort: 'Löpnr',
  scheduledtasks: 'Tidsstyrda bakgrundsjobb',
  schedule: 'Schema',
  scheduledprocess: 'Tidsstyrd process',
  scheduledprocesses: 'Tidsstyrda processer',
  scheduledprocessuserrole: 'Tidsstyrd process - Användarroll',
  scheduledprocessuserroles: 'Tidsstyrd process - Användarroll',
  shortdescription: 'Kort beskrivning',
  shortdescriptionEn: 'Engelsk kort beskrivning',
  shortPeriod: 'Månad och År - kort beskrivning',
  showtype: 'Visning',
  showreason: 'Visa orsak',
  showperiod: 'Visa period',
  signedbytenant: 'Signerat av hyresgäst',
  signedbylandlord: 'Signerat av hyresvärd',
  size: 'Storlek',
  sizesw: 'Storlek (Sv)',
  sizeen: 'Storlek (Eng)',
  sortorder: 'Sorteringsordning',
  specialprovisions: 'Särskilda villkor',
  specification: 'Specifikation',
  specificcost: 'Specifik kostnad',
  starttime: 'Startat',
  startstudycontrol: 'Starta studiekontroll',
  studycontrolexchange: 'Studiekontroll, läs in Utbyte',
  studycontrolpermanent: 'Permanent studiekontroll',
  studycontrolcreate: 'Studiekontroll, skapa excelark',
  content_studycontrolexcel: 'Studiekontroll, skapa excelark',
  studycontrolclear: 'Återställ pågående studiekontrol',
  startmembercontrol: 'Starta medlemskontroll',
  stoptime: 'Avslutas',
  storey: 'Våning',
  storeys: 'Våningar',
  streetaddress: 'Gatuadress',
  streetaddress1: 'Gatuadress',
  streetandnumber: 'Gatuadress',
  streetnumber: 'Gatunummer',
  streetnumber1: 'Gatunummer',
  subgroup: 'Undergrupp',
  subject: 'Ämne',
  subjectrow: 'Ämnesrad',
  subletid: 'Andrahandsansökan id',
  suppliers: 'Leverantörer',
  supplier: 'Leverantör',
  suppliercontact: 'Kontaktperson',
  suppliercontacts: 'Kontaktpersoner',
  suppliername: 'Leverantör',
  systemactions: 'Systemhandlingar',
  systeminfo: 'Systeminformation',
  systemlogs: 'Systemloggar',
  systemparameter: 'Systemparameter',
  systemparameters: 'Systemparametrar',
  'systemparameter.type.date': 'Datum',
  'systemparameter.type.email': 'Epost',
  'systemparameter.type.text': 'Text',
  'systemparameter.type.printer': 'Skrivare',
  sublet: 'Andrahandsavtal',
  sublets: 'Andrahandsavtal',
  sublettings: 'Andrahandsavtal',
  subletapplications: 'Andrahandsansökningar',
  subletapplication: 'Andrahandsansökan',
  sqrmtrs: 'Area',
  sqrmtrstotal: 'Total area',
  totalsqrmtrs: 'Total area',
  switchsymbol: 'Byt tecken på alla belopp',
  table: 'Register',
  tags: 'Taggar meddelandetexter',
  template: 'Meddelandetext',
  templates: 'Meddelandetexter',
  'template.type.automatic': 'Automatiskt utskick',
  'template.type.manual': 'Manuellt utskick',
  'template.type.manualandautomatic': 'Manuellt och automatiskt utskick',
  tenant: 'Bostadshyresgäst',
  term: 'Termin',
  terms: 'Terminer',
  textmessagebody: 'SMS-text',
  totalamount: 'Totalt belopp',
  trackchange: 'Uppdatera i Insikt',
  trackchangelogs: 'Trackchangeloggar',
  tree: 'Navigeringsträd',
  unidentified: 'Oidentifierade',
  unidentify: 'Oidentifierad',
  unit: 'Enhet',
  unitcost: 'Pris per enhet',
  unmatched: 'Ej matchade',
  unmark: 'Avmarkera',
  update: 'Uppdatera',
  upload: 'Ladda upp',
  uploadfile: 'Ladda upp fil',
  uploadfileroutine: 'Ladda upp fil/Hämta fil',
  usekey: 'Servicenyckel',
  usermanagement: 'Användarhantering',
  user: 'Användare',
  users: 'Användare',
  userrole: 'Användarroll',
  userroles: 'Användarroller',
  userroledescription: 'Användarroll',
  useruserroles: 'Användare - Användarroller',
  useruserrole: 'Användare - Användarroll',
  useofpremises: 'Lokalens användning',
  unpaidreport: 'Rapport ej debiterat',
  vacant_account: 'Outhyrt Konto',
  vacant_vataccount: 'Outhyrt Momskonto',
  vacant_activity: 'Outhyrt Aktivitet',
  vacant_accountlocation: 'Outhyrt Var',
  vacant_costcenter: 'Outhyrt Kostnadsställe',
  vacant_purpose: 'Outhyrt Ändamål',
  vacant_counterpart: 'Outhyrt Motpart',
  vacancyreport: 'Rapport outhyrt',
  validate: 'Kontrollera',
  valuetype: 'Värdetyp',
  vat: 'Moms',
  vatdeductionpart: 'Avdragsgill momsandel',
  vatincluded: 'Inklusive ej avdragsgill moms',
  vataccount: 'Momskonto',
  workphone: 'Arbetstelefon',
  workplace: 'Arbetsplats',
  verified: 'Verifierad',
  vouchers: 'Verifikationer',
  voucher: 'Verifikation',
  workername: 'Utförare namn',
  zipcode: 'Postnummer',
  year: 'År',
  yyyymm: 'ÅÅÅÅMM',
  validation: {
    datepassed: 'Datumet har redan passerats',
    emailNotValid: 'Felaktig e-postadress',
    optional: {
      invalid: 'Felaktigt värde',
      required: 'Måste anges',
      minlength: 'För få tecken, minst ',
      maxlength: 'För många tecken max ',
    },
  },
  documentationRoles: 'Roller',
  documentationPermissions: 'Rättigheter',
  documentationTags: 'Taggar Meddelandetexter',
  documentationProcesses: 'Processer',
  database: 'Databas',
  buildId: 'Bygg-id',
  buildDate: 'Bygg-datum',
  profile: 'Profil',
  keyloanOrder: 'Beställning',
  whoOrderedWork: 'Vem på AF Bostäder har beställt arbetet',
  companyName: 'Företagsnamn',
  contact: 'Kontaktpersoner',
  borrowerName: 'Uthämtarens namn',
  keyCollectDate: 'Datum då nycklar/taggar hämtas',
  keyReturnDate: 'Datum då nycklar/taggar återlämnas',
  keyOrderAreaLabel: 'Till vad behöver du nycklar',
  tagIsNeeded: 'Tagg behövs',
  servicekeyIsNeeded: 'Servicenyckel behövs',
  registrationNumbers: 'Registreringsnummer (för parkeringstillstånd)',
  orderSent: 'Beställningen skickad',
  productkeyareas: 'Nyckel - Område',
  productkeykeyloans: 'Nyckel - Utlåning',
  tagrequired: 'Tagg krävs',
  keyloan: 'Nyckelutlåning',
  loanfrom: 'Hämtas',
  loanuntil: 'Återlämnas',
  companyname: 'Företag',
  loading: 'Hämtar...',
  'employeerole.type.manager': 'Chef',
  'employeerole.type.customerservice': 'Kundservice',
  'employeerole.type.caretaker': 'Bovärd',
  'employeerole.type.worker': 'Utförare',
  'employeerole.type.inspector': 'Besiktningsman',
  'employeerole.type.servicecenter': 'Servicecenter',
  'employeerole.type.other': 'Övriga',
  route: 'Sökväg',
  page: 'Sida',
  additionalInfo: 'Övrig information',
  uploadFile: 'Ladda upp fil',
  nofilesInFolder: 'Inga filer i angiven mapp',
  chooseFileType: 'Välj filtyp',
  deleteFileQuestion: 'Vill du radera filen: ',
  deleteFile: 'Radera filen',
  downloadFileQuestion: 'Vill du ladda ner filen: ',
  downloadFile: 'Ladda ner fil',
  unauthorizedErrorMessage: 'Förnya din inloggning genom att ladda om sidan',
  fileuploadStatusInprogress: 'Läser in, antal rader: ',
  fileuploadStatusFinished: 'Inläsning klar, antal rader: ',
  fileuploadButton: 'Ladda upp och läs in',
  fileuploadStatusHeader: 'Status inläsning',
  fileuploadSelectFileHeader: 'Välj fil',
  servicedescription: 'Tjänst',
  keyloanPermanent: 'Permanent utlåning',
  moveinReady: 'Inflyttningsklar',
  'clear.status.new': 'Ny',
  'clear.status.printed': 'Utskriven',
  'clear.status.failed': 'Fel!',
  'clear.status.done': 'Utförd',
  'clear.type.customer': 'Kund',
  'clear.type.contract': 'Avtal',
  'clear.type.rent': 'Produktbundet hyrestillägg',
  'clear.type.customercontact': 'Kontakttillfälle',
  'clear.type.customerrestriction': 'Kundspärr',
  'clear.type.customercheck': 'Kundkontroll',
  'clear.type.customercheckok': 'Kundkontroll (endast OK)',
  'clear.type.rentalrestriction': 'Uthyrningsspärr',
  'clear.type.exemptionperiod': 'Dispensperiod',
  'clear.type.product': 'Produkt',
  'clear.type.maintobject': 'Fastighetsobjekt',
  'clear.type.employee': 'Medarbetare',
  applications: 'Applikationer',
  warnings: 'Varningar',
  warning: 'Varning',
  tabApplications: 'Applikationer',
  tabQueues: 'Köer',
  noOfTotalMessages: 'Totalt antal meddelanden',
  noOfReadyMessages: 'Tillgängliga meddelanden',
  noOfUnackedMessages: 'Meddelanden under bearbetning',
  appTags: 'Taggar',
  operationalMonitoring: 'Systemövervakning',
  countToMove: 'Ange mängd som skall flyttas',
  graph: 'Diagram',
};
